import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
} from "@components/ui/card";
import Footer from "@features/Auth/components/Footer";
import { Mail } from "lucide-react";
import { Link } from "react-router-dom";
import Linkedin from "@assets/icons/socialMedia/linkedin.svg?react";
import Discord from "@assets/icons/socialMedia/discord.svg?react";
import XTwitter from "@assets/icons/socialMedia/x.svg?react";
import { Button } from "@components/ui/button";

const ContactUs = () => {
  return (
    <div className="w-full h-auto flex flex-col min-h-screen bg-background ">
      <header className="  md:px-10 px-5 md:h-14 h-12 shadow flex items-center  dark:border-b bg-background sticky top-0">
        <div className="container mx-auto md:px-4 px-2 py-4 flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <Link to="/">
              <img
                src={window.origin + "/suppgram.png"}
                alt="suppgram"
                className="md:h-8 h-6 w-auto"
              />
            </Link>
          </div>
          <nav>
            <Button variant="ghost" asChild>
              <Link to="/">Home</Link>
            </Button>
          </nav>
        </div>
      </header>
      <main className="h-full w-full flex flex-col flex-1">
        <div className="flex-1 flex justify-center items-center">
          <Card className="w-full md:w-1/3 mx-auto ">
            <CardHeader>
              <CardTitle className="text-3xl font-bold text-center">
                Contact Us
              </CardTitle>
              <CardDescription className="text-center mt-2">
                We'd love to hear from you! Whether you have questions, ideas,
                or want to get involved, we're here to connect.
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-6">
              <p className="text-sm text-muted-foreground text-center">
                Reach out to us—we're looking for passionate partners to help
                grow this project globally and create a positive impact
                together!
              </p>

              <div className="grid gap-4">
                <Link
                  target="_blank"
                  to="mailto:suppgram.com@gmail.com"
                  className="flex items-center p-3 bg-secondary rounded-lg hover:bg-secondary/80 transition-colors"
                >
                  <Mail className="h-5 w-5 mr-3 text-primary" />
                  <span>suppgram.com@gmail.com</span>
                </Link>
                <Link
                  to="https://www.linkedin.com/company/suppgram/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center p-3 bg-secondary rounded-lg hover:bg-secondary/80 transition-colors"
                >
                  <Linkedin className="h-5 w-5 mr-3 text-primary" />
                  <span>LinkedIn</span>
                </Link>
                <Link
                  to="https://discord.gg/xbe5eJFc"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center p-3 bg-secondary rounded-lg hover:bg-secondary/80 transition-colors"
                >
                  <Discord className="h-5 w-5 mr-3 text-primary" />
                  <span>Discord</span>
                </Link>
                <Link
                  to="https://x.com/suppgram"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center p-3 bg-secondary rounded-lg hover:bg-secondary/80 transition-colors"
                >
                  <XTwitter className="h-5 w-5 mr-3 text-primary" />
                  <span>X (Twitter)</span>
                </Link>
              </div>
            </CardContent>
          </Card>
        </div>
        <Footer />
      </main>
    </div>
  );
};

export default ContactUs;
