import { ApolloClient, InMemoryCache, ApolloLink, Observable } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getRefreshToken, getUserToken, removeUser } from '@hooks/useAuthManger';
import { onError } from '@apollo/client/link/error';
import refreshToken from '@features/Auth/service/refreshToken';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'
import { toast } from '@components/ui/use-toast';

// const httpLink = createUploadLink({
//     uri: 'http://127.0.0.1:8080/query',
// });
const httpLink = createUploadLink({
    uri: 'https://suppgram-go-production.up.railway.app/query',
});
const authLink = setContext((_operation, { headers }) => {
    const token = getUserToken()

    if (token)
        return {
            headers: {
                ...headers,
                authorization: `Bearer ${token}`,
            },
        };
    else return headers
});



const errorLink = onError(({ graphQLErrors, operation, forward }) => {
    if (graphQLErrors) {
        for (const err of graphQLErrors) {

            if (err.message == "User is not authenticated") {
                const token = getRefreshToken()

                if (token)
                    return new Observable(observer => {
                        client.mutate({
                            mutation: refreshToken,
                            variables: { refreshToken: token }
                        }).then(response => {
                            if (response.data) {
                                const { refreshToken } = response.data
                                if (refreshToken && refreshToken.success) {
                                    // updateUserToken(refreshToken.token, refreshToken.refreshToken)
                                }
                                else if (refreshToken.success == false) {
                                    const error = refreshToken['errors']['nonFieldErrors']

                                    if (error && error[0] && error[0].code == "invalid_token") {
                                        removeUser().then(() => {
                                            setTimeout(() => window.location.reload(), 500)
                                        })

                                    }
                                }
                            }

                        })
                            .then(() => {
                                const subscriber = {
                                    next: observer.next.bind(observer),
                                    error: observer.error.bind(observer),
                                    complete: observer.complete.bind(observer),
                                };
                                forward(operation).subscribe(subscriber);
                            })
                            .catch(error => {
                                observer.error(error);
                            });
                    })
            }
            else {
                toast({
                    title: "Error",
                    description: err.message,
                    variant: 'destructive'
                })
            }
        }
    }

})

const client = new ApolloClient({
    link: ApolloLink.from([errorLink, authLink, httpLink]),
    cache: new InMemoryCache(),
});

export default client;