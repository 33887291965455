import { createClient } from '@supabase/supabase-js'
import { USER_SESSION } from '@utils/constants'

const supabase = createClient(import.meta.env.VITE_SUPABASE_URL, import.meta.env.VITE_SUPBASE_SECREATE_KEY, {
    auth: {
        storage: localStorage,
        storageKey: USER_SESSION
    }
})

export default supabase