import { create } from "zustand";

export interface UserProfilePaginationType {
  edges: {
    node: UserProfileType;
  }[];
  totalCount: number;
  pageInfo: {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor: string;
    endCursor: string;
  };
}
export interface UserProfileType {
  description: string;
  firstName: string;
  id: string;
  avatar?: string;
  isOnline: boolean;
  lastName: string;
  modifiedDate: string;
  totalBalance: number;
  currentBalance: number;
  totalSuppCoin: number;
  fullName: string;
  createdAt: string;
  followerCount: number;
  followingCount: number;
  email: string;
  profession: string;
  isFollowing?: boolean;
  username: string;
  newNotifications?: number;
}
export type IsFollowingType = { [key: string]: boolean };
interface useProfileStoreType {
  profile?: UserProfileType;
  isDepositModalOpen: boolean;
  withdrawOpen: boolean;
  currentUserProfile?: UserProfileType & { isFollowing: boolean };
  following: IsFollowingType;
  setWithdrawOpen: (isOpen: boolean) => void;
  setProfile: (profile?: UserProfileType) => void;
  setCurrentUserProfile: (
    currentUserProfile?: UserProfileType & { isFollowing: boolean }
  ) => void;
  setIsDepositModalOpen: (isOpen: boolean) => void;
  addFollowing: (data: IsFollowingType) => void;
}

const useProfileStore = create<useProfileStoreType>((set) => ({
  profile: undefined,
  following: {},
  isDepositModalOpen: false,
  currentUserProfile: undefined,
  withdrawOpen: false,
  setWithdrawOpen: (isOpen: boolean) => set({ withdrawOpen: isOpen }),
  setProfile: (profile?: UserProfileType) => {
    set({ profile });
  },
  setIsDepositModalOpen: (isOpen: boolean) => {
    set({ isDepositModalOpen: isOpen });
  },
  setCurrentUserProfile: (
    currentUserProfile?: UserProfileType & { isFollowing: boolean }
  ) => {
    set({ currentUserProfile });
  },
  addFollowing(data: IsFollowingType) {
    set({
      following: data,
    });
  },
}));

export default useProfileStore;
