import { Session, User } from "@supabase/supabase-js";
import { create } from "zustand";
import { persist } from "zustand/middleware";

interface userAuthType {
  user?: User;
  session?: Session;
  getUser: () => User | undefined;
  setUser: (user?: User) => void;
  setSession: (session?: Session) => void;
}

const useAuthStore = create<userAuthType>()(
  persist(
    (set, get) => ({
      user: undefined,
      session: undefined,
      setUser: (user?: User) => {
        set({ user: user });
      },
      getUser: (): User | undefined => {
        return get().user;
      },
      setSession: (session?: Session) => {
        set({ session: session });
      },
    }),

    {
      name: "suppgram-auth",
      version: 2,
    }
  )
);

export default useAuthStore;
