import { PageInfo } from "@features/Posts/types";
import { create } from "zustand";

export interface NotificationType {
    id: string;
    actionType: string;
    createdAt: string;
    seen: boolean;
    amount?: number;
    sentFrom: {
        avatar: string;
        fullName: string
        username: string
    }
}

interface useNotificationType {
    notifications: { node: NotificationType }[];
    pageInfo?: PageInfo;
    setPageInfo: (pageInfo: PageInfo) => void
    setNotifications: (notification: { node: NotificationType }[]) => void
}

const useNotificationStore = create<useNotificationType>((set) => ({
    notifications: [],
    pageInfo: { hasNextPage: false, hasPreviousPage: false, endCursor: "", startCursor: "" },
    setPageInfo: (pageInfo: PageInfo) => {
        set({ pageInfo })
    },
    setNotifications: (notifications: { node: NotificationType }[]) => {
        set({ notifications })
    }
}))

export default useNotificationStore