import mixpanel from 'mixpanel-browser';
mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN
    , {
        debug: true,
        track_pageview: true,
        persistence: "localStorage",
        ignore_dnt: true,

    });
interface TrackProps {
    [key: string]: object | string | number | boolean;
}

const actions = {
    track: (name: string, props: TrackProps) => {
        mixpanel.track(name, props)
    },
    identify: (id: string) => {
        mixpanel.identify(id);
    },
    // Add other Mixpanel methods as needed
};

export default actions;

