import { gql } from '@apollo/client'

const getUserProfileById = gql`
        query userDetailById($id:ID!){
        userDetailById(
            id:$id
        ){
            id
            email,
            firstName
            lastName
            profession
            description
            createdAt
            updatedAt
            avatar
            username
            phone
            isFollowing
            totalBalance
            currentBalance
            newNotifications
            followerCount
            followingCount
            fullName
        }
    } 
`

export default getUserProfileById