import { Button } from "@components/ui/button";
import { isMobile } from "react-device-detect";
import { Link, useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="mb-2 flex flex-col justify-center items-center gap-1">
      <div className="flex md:gap-3 gap-1 flex-wrap items-center justify-center">
        <Button
          variant="ghost"
          className="text-info"
          size={isMobile ? "sm" : "default"}
          onClick={(e: React.MouseEvent) => {
            e.preventDefault();
            navigate("/");
          }}
        >
          Home
        </Button>
        <Link to="/terms-of-use">
          <Button
            variant="ghost"
            className="text-info"
            size={isMobile ? "sm" : "default"}
          >
            Terms
          </Button>
        </Link>
        <Link to="/privacy-policy">
          <Button
            variant="ghost"
            className="text-info"
            size={isMobile ? "sm" : "default"}
          >
            Privacy Policy
          </Button>
        </Link>
        <Link to="/contact-us" replace>
          <Button
            variant="ghost"
            className="text-info"
            size={isMobile ? "sm" : "default"}
          >
            Contact
          </Button>
        </Link>
        <Link to="/about-us" replace>
          <Button
            variant="ghost"
            className="text-info"
            size={isMobile ? "sm" : "default"}
          >
            About
          </Button>
        </Link>
      </div>
      <div className="text-sm text-info">
        © Copyright 2021. All rights reserved.
      </div>
    </div>
  );
};

export default Footer;
