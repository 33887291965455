import { gql } from '@apollo/client'

const refreshToken = gql`
 mutation RefreshToken($refreshToken: String!) {
    refreshToken(
      input: {
        refreshToken: $refreshToken
        revokeRefreshToken: false
      }
    ) {
      errors
      success
      refreshToken {
        token
        isExpired
      }
      token {
        token
        payload {
          origIat
          exp
        }
      }
    }
 }
`

export default refreshToken