import { useCallback, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client';

import GET_NOTIFICATION from '@features/Notifications/services/getNotification';
import useNotificationStore from '@features/Notifications/store/useNotification';
import GET_CURRENT_USER_DETAIL from '@features/Profile/service/getUserProfileById';
import supabase from '@libs/supbase';
import useAuthStore from '@store/useAuthStore'
import useProfileStore from '@store/useProfileStore';
import { ACCESS_TOKEN } from '@utils/constants';


export const getUserToken = () => {
    const token = localStorage.getItem(ACCESS_TOKEN)
    return token
}

export const getRefreshToken = async () => {
    const session = await supabase.auth.getSession()
    return session?.data.session?.refresh_token
}

export const removeUser = async () => {
    localStorage.setItem('suppcoins-auth', "")
    await useAuthStore.persist.rehydrate()
}

const useAuthManger = () => {
    const { user, getUser, setUser, session, setSession } = useAuthStore()
    const { profile, setProfile, currentUserProfile, setIsDepositModalOpen, setWithdrawOpen } = useProfileStore();
    const { setPageInfo, setNotifications, notifications, pageInfo } = useNotificationStore()


    const [loadCurrentProfile, { loading, data, client }] = useLazyQuery(GET_CURRENT_USER_DETAIL)
    const [getNotificationFN, { data: notificationData, loading: notificationLoading }] = useLazyQuery(GET_NOTIFICATION);

    const loadMoreNotification = useCallback(() => {
        getNotificationFN({
            variables: {
                first: 10,
                after: pageInfo?.endCursor,
                orderBy: {
                    field: "created_at",
                    direction: "DESC"
                }
            },
            onCompleted: (data) => {
                if (data.notifications) {
                    console.log(data.notifications.edges)
                    console.log("new data ", [...notifications, ...data.notifications.edges])
                    setNotifications([...notifications, ...data.notifications.edges])
                    setPageInfo(data.notifications.pageInfo)
                }
            }
        })
    }, [notifications, pageInfo])

    useEffect(() => {
        if (data && data['userDetailById']) {
            const usersDetail = data['userDetailById']
            if (usersDetail) {
                setProfile(usersDetail)
                getNotificationFN({
                    variables: {
                        first: 10,
                        orderBy: {
                            field: "created_at",
                            direction: "DESC"
                        }
                    },
                    onCompleted: (data) => {
                        if (data.notifications) {
                            setNotifications(data.notifications.edges)
                            setPageInfo(data.notifications.pageInfo)
                        }
                    }
                })
            }
        }
    }, [data])

    const logout = useCallback(() => {
        setUser(undefined)
        setProfile(undefined)
        supabase.auth.signOut()
        client.resetStore()
    }, [setUser])

    const getUserDetailId = useCallback(() => {
        if (user) {
            const userDetail = user.user_metadata.userDetail
            if (userDetail) {
                return userDetail
            }
        }
        return undefined
    }, [user])

    return {
        user,
        loading,
        session,
        profile,
        setSession,
        getUser,
        setUser,
        setProfile,
        logout,
        setWithdrawOpen,
        getUserDetailId,

        setIsDepositModalOpen,
        loadMoreNotification,
        loadCurrentProfile,
        currentUserProfile,

        notificationLoading,
        notificationData
    }
}

export default useAuthManger