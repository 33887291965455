import { gql } from '@apollo/client'

const getNotification = gql`
    query notifications($after: Cursor, $first: Int,$orderBy: NotificationOrder){
        notifications(
            after: $after
            first: $first
            orderBy: $orderBy
        ) {
            edges{
                node{
                    id
                    actionType
                    createdAt
                    seen
                    amount
                    sentFrom {
                        avatar
                        fullName
                        username
                    }
                }
            }
            pageInfo{
                hasNextPage
                hasPreviousPage
                endCursor
                startCursor
          }
          totalCount
        }
    }
`

export default getNotification